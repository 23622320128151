#Homepage section {
    background-color: #eee;
    padding: 20px;
    padding-bottom: 150px;
}
#Homepage .options {
    display: flex;
    justify-content: center;
    align-items: center;
}
#Homepage .options .option {
    margin: 20px;
    padding: 50px;
    background-color: white;
    width: min-content;
    flex-grow: 1;
}
#Homepage .options .divider {
    border: 5px solid green;
    border-radius: 100%;
    padding: 10px;
}
#Homepage .options .divider span {
    font-weight: bold;
    font-size: larger;
}