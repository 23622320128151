@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');
.App {
  font-family: 'Roboto', sans-serif;
}

table {
  font-family: Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

table td, table th {
  border: 1px solid #d8d8d8;
  padding: 8px;
}

table tr:nth-child(odd){background-color: rgb(212, 212, 212);}


table tr:hover {background-color: #adc4b7}

table th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: left;
  background-color: #1a884d;
  color: white;
}