@import url(https://fonts.googleapis.com/css2?family=Roboto&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
  font-family: 'Roboto', sans-serif;
}

table {
  font-family: Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

table td, table th {
  border: 1px solid #d8d8d8;
  padding: 8px;
}

table tr:nth-child(odd){background-color: rgb(212, 212, 212);}


table tr:hover {background-color: #adc4b7}

table th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: left;
  background-color: #1a884d;
  color: white;
}
body {
    background-image: url(/static/media/uop-library.551a83e0.jpeg);
    background-size: cover;
    background-color: #000;
}
#LoginBox {
    position: relative;
    width: calc(100% - 100px);
    max-width: 300px;
    background-color: #eee;
    margin: 100px auto 0 auto;
    text-align: center;
    padding: 20px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 2px 2px 0px, rgba(0, 0, 0, 0.24) 0px 0px 1px 0px;
}

#LoginBox .logo {
    width: 50%;
    margin: 10px auto;
}

#LoginBox h1.title {
    text-transform: uppercase;
    color: #1baf61;
}

#LoginBox .alert {
    padding: 10px;
    margin: 5px;
}
/* Header & Navigatioon */
header {
    width: 100%;
}
header ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    overflow: hidden;
    background-color: #313131;
  } 
header li {
    float: left;
  }
header li a {
    display: block;
    color: #fff;
    text-decoration: none;
}
header li:not(.logo) a {
    padding: 14px 16px;
}
header li a:hover {
    background-color: #111;
}
header li button {
    /* Remove the default styles */
    background: none;
    color: inherit;
    border: none;
    padding: 0;
    font: inherit;
    cursor: pointer;
    outline: inherit;
    color: white;
    padding: 14px 16px;
}
header li button:hover {
    background-color: #111;
}
header .logout-button-container {
    float: right;
}
header .logout-button-container button {
    background-color: #1baf61;
}
header .logout-button-container button:hover {
    background-color: #1baf61;
}
header #main-logo {
    height: 25px;
    padding: 10px;
}

footer {
    background-color: #313131;
    padding: 10px;
    color: white;
    text-align: center;
}

.Banner {
    background-color: #1a884d;
    color: white;
    padding: 20px;
    text-align: center;
}

/* SidebarLayout */
.SidebarLayout {
    width : 100vw;
    display:flex;
}
.SidebarLayout aside {
    flex : 1 1;
    display:flex;
    flex-direction : column;
    background-color:rgb(77, 77, 77);
    padding: 10px;
    min-height: calc(100vh);
}
.SidebarLayout main {
    flex:3 1;
    background-color : white;
    padding: 10px 30px 10px 30px;
}

/**
 ** Participant View
 **/

.JoinEventForm {
    padding: 10px;
    min-width: 200px;
}
.JoinEventForm label {
    display: block;
    font-weight: bold;
    color: rgb(155, 155, 155);
    margin: 5px;
    text-align: center;
    font-size: 10px;
}
.JoinEventForm input[type="text"] {
    box-sizing: border-box;
    text-align: center;
    padding: 10px;
    border: 2px solid #333;
    border-bottom: none;
    border-radius: 5px 5px 0 0;
    font-weight: 900;
    font-size: 20px;
    width: 100%;
}
.JoinEventForm input[type="submit"] {
    box-sizing: border-box;
    text-align: center;
    padding: 10px;
    border: 2px solid #333;
    background-color: #1baf61;
    border-top: none;
    border-radius: 0 0 5px 5px;
    font-size: 20px;
    width: 100%;
    color: white;
}
.JoinEventForm input[type="submit"]:hover {
    background-color: #1a884d;
}

/**
 ** Organiser View
 **/
.CreateEventButton {
    min-width: 200px;
    box-sizing: border-box;
    text-align: center;
    padding: 10px;
    border: 2px solid #333;
    background-color: #1baf61;
    border-radius: 5px;
    font-size: 20px;
    width: 100%;
    color: white;
}
.CreateEventButton:hover {
    background-color: #1a884d;
}
/* Event list */
.EventListItem {
    padding: 15px;
    text-align: left;
    background-color: rgb(104, 104, 104);
    border-left: 2px solid #333;
    border-right: 2px solid #333;
    font-size: 20px;
    color: white;
}
.EventListItem:first-child {
    margin-top: 10px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    border-top: 2px solid #333;
}
.EventListItem:last-child {
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    border-bottom: 2px solid #333;
}
.EventListItem:nth-child(2n-1) {
    background-color: rgb(136, 136, 136);
}
.EventListItem:hover {
    background-color: #370b0542;
}
.EventListItem.selected {
    background-color: #1a884d;
    position: relative;
}
.EventListItem.selected:after, .EventListItem.selected:before {
	content: '';
	display: block;  
	position: absolute;
	left: 100%;
	top: 50%;
	margin-top: -20px;
	width: 0;
	height: 0;
	border-top: 20px solid transparent;
	border-right: 20px solid transparent;
	border-bottom: 20px solid transparent;
	border-left: 20px solid #1a884d;
}
.EventListItem.selected:before {
    border-left: 20px solid rgb(77, 77, 77);
    left: calc(100% + 11px);
}
.EventListItem p {
    padding: 0;
    margin: 0;
}

.EventManager {
    text-align: left;
    padding: 10px 10px 10px 20px;
}
.EventManager .topBar {
    display: flex;
    flex-flow: row nowrap;
}
.EventManager .addItem {
    text-align: center;
}
.EventManager .joinCode {
    margin-left: auto;
}
.EventManager .joinCode span {
    text-align: center;
    padding: 10px;
    background-color: #1baf61;
    border-radius: 5px;
    font-size: 15px;
    font-weight: 900;
    color: white;

}
.EventManager input[type="text"] {
    box-sizing: border-box;
    padding: 10px;
    border: 1px solid #333;
    border-radius: 5px;
    font-size: 15px;
    width: 100%;
}
.feedbackFormContainer {
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    align-items: stretch;
}
.FeedbackItem {
    flex-grow: 1;
    border-radius: 5px;
    margin: 10px;
    padding: 10px;
    background-color: #eee;
}
  
.LoadingMessage {
    position: relative;
}

.LoadingMessage span {
    display: block;
    margin-top: 5px;
    font-weight: bold;
    color: #1a884d;
}
  
.LoadingMessage .dot {
    -webkit-animation: expand .75s ease-in-out infinite;
            animation: expand .75s ease-in-out infinite;
    border-radius: 20px;
    display: inline-block;
    -webkit-transform-origin: center center;
            transform-origin: center center;
    margin: 0 3px;
    width: 10px;
    height: 10px;
    background: #1a884d;
}

.LoadingMessage .dot:nth-child(2) {
    -webkit-animation-delay: 180ms;
            animation-delay: 180ms;
}

.LoadingMessage .dot:nth-child(3) {
    -webkit-animation-delay: 360ms;
            animation-delay: 360ms;
}

.LoadingMessage .dot:nth-child(4) {
    -webkit-animation-delay: 540ms;
            animation-delay: 540ms;
}

@-webkit-keyframes expand {
    0% {
        -webkit-transform: scale(1);
                transform: scale(1);
    }
    25% {
        -webkit-transform: scale(1.55);
                transform: scale(1.55);
    }
}

@keyframes expand {
    0% {
        -webkit-transform: scale(1);
                transform: scale(1);
    }
    25% {
        -webkit-transform: scale(1.55);
                transform: scale(1.55);
    }
}
.PageSection {
    position: relative;
    margin-top: 10px;
    padding: 20px;
    background-color: #eee;
    border-radius: 10px;
}
.NavigateButton {
    display: inline-block;
    padding: 10px;
    background-color: #1baf61;
    border-radius: 5px;
    font-size: 15px;
    color: white;
    border: none;
}
.NavigateButton:hover {
    background-color: #106437;
}
.SyncingNotice {
    border-radius: 20px;
    padding: 5px;
    font-size: 10px;
    color: white;
    font-weight: bold;
    position: absolute;
    top: 10px;
    right: 10px;
}
#Timeline {
    box-sizing: border-box;
    position: relative;
  }
  
  /* The actual timeline (the vertical ruler) */
  .timeline {
    padding: 0;
    position: relative;
    max-width: 1200px;
    margin: 0 auto;
  }
  
  /* The actual timeline (the vertical ruler) */
  .timeline::after {
    content: '';
    position: absolute;
    width: 6px;
    background-color: white;
    top: 0;
    bottom: 0;
    left: 50%;
    margin-left: -3px;
  }
  
  /* timeline-item around content */
  .timeline-item {
    padding: 10px 40px;
    position: relative;
    background-color: inherit;
    width: 50%;
  }
  
  /* The circles on the timeline */
  .timeline-item::after {
    content: '';
    position: absolute;
    width: 25px;
    height: 25px;
    right: -17px;
    background-color: white;
    border: 4px solid #1a884d;
    top: 15px;
    border-radius: 50%;
    z-index: 1;
  }
  
  /* Place the timeline-item to the left */
  .left {
    left: 0;
  }
  
  /* Place the timeline-item to the right */
  .right {
    left: 50%;
  }
  
  /* Add arrows to the left timeline-item (pointing right) */
  .left::before {
    content: " ";
    height: 0;
    position: absolute;
    top: 22px;
    width: 0;
    z-index: 1;
    right: 30px;
    border: medium solid white;
    border-width: 10px 0 10px 10px;
    border-color: transparent transparent transparent white;
  }
  
  /* Add arrows to the right timeline-item (pointing left) */
  .right::before {
    content: " ";
    height: 0;
    position: absolute;
    top: 22px;
    width: 0;
    z-index: 1;
    left: 30px;
    border: medium solid white;
    border-width: 10px 10px 10px 0;
    border-color: transparent white transparent transparent;
  }
  
  /* Fix the circle for timeline-items on the right side */
  .right::after {
    left: -16px;
  }
  
  /* The actual content */
  .content {
    padding: 20px 30px;
    background-color: white;
    position: relative;
    border-radius: 6px;
  }
  
  /* Media queries - Responsive timeline on screens less than 600px wide */
  /* Place the timelime to the left */
    .timeline::after {
      left: 31px;
    }
  
  /* Full-width timeline-items */
    .timeline-item {
      width: calc(100% - 80px);
      padding-left: 70px;
      padding-right: 25px;
    }
  
  /* Make sure that all arrows are pointing leftwards */
    .timeline-item::before {
      left: 60px;
      border: medium solid white;
      border-width: 10px 10px 10px 0;
      border-color: transparent white transparent transparent;
    }
  
  /* Make sure all circles are at the same spot */
    .left::after, .right::after {
      left: 15px;
    }
  
  /* Make all right timeline-items behave like the left ones */
    .right {
      left: 0%;
    }
.SaveButton button {
    display: inline-block;
    padding: 10px;
    background-color: #1baf61;
    border-radius: 5px;
    font-size: 15px;
    color: white;
    border: none;
}
.SaveButton:hover button {
    background-color: #106437;
}
#Homepage section {
    background-color: #eee;
    padding: 20px;
    padding-bottom: 150px;
}
#Homepage .options {
    display: flex;
    justify-content: center;
    align-items: center;
}
#Homepage .options .option {
    margin: 20px;
    padding: 50px;
    background-color: white;
    width: -webkit-min-content;
    width: min-content;
    flex-grow: 1;
}
#Homepage .options .divider {
    border: 5px solid green;
    border-radius: 100%;
    padding: 10px;
}
#Homepage .options .divider span {
    font-weight: bold;
    font-size: larger;
}

