body {
    background-image: url(../../images/uop-library.jpeg);
    background-size: cover;
    background-color: #000;
}
#LoginBox {
    position: relative;
    width: calc(100% - 100px);
    max-width: 300px;
    background-color: #eee;
    margin: 100px auto 0 auto;
    text-align: center;
    padding: 20px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 2px 2px 0px, rgba(0, 0, 0, 0.24) 0px 0px 1px 0px;
}

#LoginBox .logo {
    width: 50%;
    margin: 10px auto;
}

#LoginBox h1.title {
    text-transform: uppercase;
    color: #1baf61;
}

#LoginBox .alert {
    padding: 10px;
    margin: 5px;
}