/* Header & Navigatioon */
header {
    width: 100%;
}
header ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    overflow: hidden;
    background-color: #313131;
  } 
header li {
    float: left;
  }
header li a {
    display: block;
    color: #fff;
    text-decoration: none;
}
header li:not(.logo) a {
    padding: 14px 16px;
}
header li a:hover {
    background-color: #111;
}
header li button {
    /* Remove the default styles */
    background: none;
    color: inherit;
    border: none;
    padding: 0;
    font: inherit;
    cursor: pointer;
    outline: inherit;
    color: white;
    padding: 14px 16px;
}
header li button:hover {
    background-color: #111;
}
header .logout-button-container {
    float: right;
}
header .logout-button-container button {
    background-color: #1baf61;
}
header .logout-button-container button:hover {
    background-color: #1baf61;
}
header #main-logo {
    height: 25px;
    padding: 10px;
}

footer {
    background-color: #313131;
    padding: 10px;
    color: white;
    text-align: center;
}

.Banner {
    background-color: #1a884d;
    color: white;
    padding: 20px;
    text-align: center;
}

/* SidebarLayout */
.SidebarLayout {
    width : 100vw;
    display:flex;
}
.SidebarLayout aside {
    flex : 1;
    display:flex;
    flex-direction : column;
    background-color:rgb(77, 77, 77);
    padding: 10px;
    min-height: calc(100vh);
}
.SidebarLayout main {
    flex:3;
    background-color : white;
    padding: 10px 30px 10px 30px;
}

/**
 ** Participant View
 **/

.JoinEventForm {
    padding: 10px;
    min-width: 200px;
}
.JoinEventForm label {
    display: block;
    font-weight: bold;
    color: rgb(155, 155, 155);
    margin: 5px;
    text-align: center;
    font-size: 10px;
}
.JoinEventForm input[type="text"] {
    box-sizing: border-box;
    text-align: center;
    padding: 10px;
    border: 2px solid #333;
    border-bottom: none;
    border-radius: 5px 5px 0 0;
    font-weight: 900;
    font-size: 20px;
    width: 100%;
}
.JoinEventForm input[type="submit"] {
    box-sizing: border-box;
    text-align: center;
    padding: 10px;
    border: 2px solid #333;
    background-color: #1baf61;
    border-top: none;
    border-radius: 0 0 5px 5px;
    font-size: 20px;
    width: 100%;
    color: white;
}
.JoinEventForm input[type="submit"]:hover {
    background-color: #1a884d;
}

/**
 ** Organiser View
 **/
.CreateEventButton {
    min-width: 200px;
    box-sizing: border-box;
    text-align: center;
    padding: 10px;
    border: 2px solid #333;
    background-color: #1baf61;
    border-radius: 5px;
    font-size: 20px;
    width: 100%;
    color: white;
}
.CreateEventButton:hover {
    background-color: #1a884d;
}
/* Event list */
.EventListItem {
    padding: 15px;
    text-align: left;
    background-color: rgb(104, 104, 104);
    border-left: 2px solid #333;
    border-right: 2px solid #333;
    font-size: 20px;
    color: white;
}
.EventListItem:first-child {
    margin-top: 10px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    border-top: 2px solid #333;
}
.EventListItem:last-child {
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    border-bottom: 2px solid #333;
}
.EventListItem:nth-child(2n-1) {
    background-color: rgb(136, 136, 136);
}
.EventListItem:hover {
    background-color: #370b0542;
}
.EventListItem.selected {
    background-color: #1a884d;
    position: relative;
}
.EventListItem.selected:after, .EventListItem.selected:before {
	content: '';
	display: block;  
	position: absolute;
	left: 100%;
	top: 50%;
	margin-top: -20px;
	width: 0;
	height: 0;
	border-top: 20px solid transparent;
	border-right: 20px solid transparent;
	border-bottom: 20px solid transparent;
	border-left: 20px solid #1a884d;
}
.EventListItem.selected:before {
    border-left: 20px solid rgb(77, 77, 77);
    left: calc(100% + 11px);
}
.EventListItem p {
    padding: 0;
    margin: 0;
}

.EventManager {
    text-align: left;
    padding: 10px 10px 10px 20px;
}
.EventManager .topBar {
    display: flex;
    flex-flow: row nowrap;
}
.EventManager .addItem {
    text-align: center;
}
.EventManager .joinCode {
    margin-left: auto;
}
.EventManager .joinCode span {
    text-align: center;
    padding: 10px;
    background-color: #1baf61;
    border-radius: 5px;
    font-size: 15px;
    font-weight: 900;
    color: white;

}
.EventManager input[type="text"] {
    box-sizing: border-box;
    padding: 10px;
    border: 1px solid #333;
    border-radius: 5px;
    font-size: 15px;
    width: 100%;
}
.feedbackFormContainer {
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    align-items: stretch;
}
.FeedbackItem {
    flex-grow: 1;
    border-radius: 5px;
    margin: 10px;
    padding: 10px;
    background-color: #eee;
}
  