.SaveButton button {
    display: inline-block;
    padding: 10px;
    background-color: #1baf61;
    border-radius: 5px;
    font-size: 15px;
    color: white;
    border: none;
}
.SaveButton:hover button {
    background-color: #106437;
}